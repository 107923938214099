import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { firebaseOptions } from "../config";

const app = initializeApp(firebaseOptions);
const auth = getAuth(app);

onAuthStateChanged(auth, (user) => {
  if (user) {
    // user is signed in -> redirect to App
    window.location.href = "/";
  } else {
    // user is signed out
    const $loginForm = document.getElementById("loginForm") as HTMLFormElement;
    const $successMsg = document.getElementById("successMsg")!;
    const $emailInput = document.getElementById(
      "emailInput"
    ) as HTMLInputElement;
    const $submitButton = document.getElementById(
      "submitButton"
    ) as HTMLButtonElement;
    // on form submit ...
    $loginForm.addEventListener("submit", async (ev) => {
      ev.preventDefault();
      // get email and settings
      const email = $emailInput.value;
      const actionCodeSettings = {
        url: window.location.origin,
        handleCodeInApp: true,
      };
      // disable form
      $emailInput.disabled = true;
      $submitButton.disabled = true;
      const submitButtonTitle = $submitButton.textContent;
      $submitButton.textContent = $submitButton.dataset["labelLoading"]!;
      // try to send sign in link to email
      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      } catch (error) {
        // enable form again
        $emailInput.disabled = false;
        $submitButton.disabled = false;
        $submitButton.textContent = submitButtonTitle;
        // show error and exit
        alert(error);
        return;
      }
      // remember email address
      try {
        window.localStorage.setItem("emailForSignIn", email);
      } catch (error) {
        // ignore (just for convenience)
      }
      // show success message
      $successMsg.removeAttribute("aria-hidden");
    });
  }
});
